import {
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/vue-query';
import {
  type ProductType,
  WISHLIST_API_BASE_URL,
} from 'configs';
import { getLocaleByCode } from 'localization';
import { Logger } from 'utils/src/logger';
import { useDialog } from '@/composables/useDialog';
import { WISHLIST_QUERY_KEY } from '@/config/wishlist';

import type { AuthDialogView } from '@/types/dialogs';

interface WishlistItem {
  designId: number;
  productType: ProductType;
}

const useWishlist = () => {
  const GqlInstance = useGql();
  const queryClient = useQueryClient();
  const { openDialog } = useDialog<AuthDialogView>('AuthDialog', 'login');
  const { customer } = useCustomer();
  const { locale } = useI18n();
  const legacyWishlistItems = useLocalStorage<{ objectId: string; productType: ProductType }[]>('wishlist', []);

  const localeHeader = computed(() => ({ 'X-Locale': getLocaleByCode(locale.value)?.iso ?? 'en-GB' }));

  const {
    data: wishlistItems,
    isPending: isLoading,
    refetch: fetchWishlist,
  } = useQuery({
    queryFn: async () => {
      if (!customer.value?.id) {
        return [];
      }

      const response = await fetch(`${WISHLIST_API_BASE_URL}/likes`, {
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          ...localeHeader.value,
        },
      });

      if (!response.ok) {
        return [];
      }

      const items = (await response.json()) as WishlistItem[];

      const { designs } = items.length
        ? await GqlInstance('Designs', {
          designIds: items.map((item) => String(item.designId)),
          localeCode: locale.value,
          nodesPerPage: 1000,
          query: '',
        })
        : { designs: { nodes: [] } };

      return items.map((item) => ({
        ...item,
        design: designs.nodes?.find((design) => design.objectId === String(item.designId)),
      }));
    },
    queryKey: WISHLIST_QUERY_KEY,
  });

  const clearWishlistItems = async () => {
    queryClient.setQueryData(WISHLIST_QUERY_KEY, () => []);

    try {
      await fetch(`${WISHLIST_API_BASE_URL}/likes`, {
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          ...localeHeader.value,
        },
        method: 'DELETE',
      });
    } catch (error) {
      Logger.error('Failed to clear wishlist', { error });
    }
  };

  const isInWishlist = (itemToCheck: WishlistItem) => !!wishlistItems.value?.some((item) => item.designId === itemToCheck.designId && item.productType === itemToCheck.productType);

  const addToWishlist = async (item: WishlistItem) => {
    if (!customer.value?.id) {
      openDialog('login', {
        afterClose: () => {
          if (customer.value) {
            addToWishlist(item);
          }
        },
      });
      return;
    }

    const isItemInWishlist = isInWishlist(item);

    queryClient.setQueryData(WISHLIST_QUERY_KEY, (oldData: WishlistItem[]) => {
      if (isItemInWishlist) {
        return oldData.filter((i) => !(i.designId === item.designId && i.productType === item.productType));
      }

      return [
        ...(oldData ?? []),
        item,
      ];
    });

    try {
      await fetch(`${WISHLIST_API_BASE_URL}/${item.designId}/${item.productType}/${isItemInWishlist
        ? 'dislike'
        : 'like'}`, {
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          ...localeHeader.value,
        },
        method: 'POST',
      });

      await queryClient.invalidateQueries({ queryKey: WISHLIST_QUERY_KEY });
      await queryClient.invalidateQueries({
        queryKey: [
          'design-likes',
          String(item.designId),
        ],
      });
    } catch (error) {
      Logger.error('Failed to update wishlist', { error });
    }
  };

  const wishlistMigrationMutation = useMutation({
    mutationFn: async (items: WishlistItem[]) => {
      // eslint-disable-next-line no-restricted-syntax
      for (const item of items) {
        if (!isInWishlist(item)) {
          try {
            // eslint-disable-next-line no-await-in-loop
            await addToWishlist(item);
            legacyWishlistItems.value = legacyWishlistItems.value.filter((i) => !(i.objectId === String(item.designId) && i.productType === item.productType));
          } catch {}
        }
      }

      await queryClient.invalidateQueries({ queryKey: WISHLIST_QUERY_KEY });
    },
    mutationKey: [ 'wishlist-migration' ],
  });
  const migrateLocalStorageWishlist = () => {
    if (!customer.value?.id) {
      return;
    }

    if (legacyWishlistItems.value.length) {
      wishlistMigrationMutation.mutate(legacyWishlistItems.value.map((item) => ({
        designId: Number(item.objectId),
        productType: item.productType,
      })));
    }
  };

  return {
    addToWishlist,
    clearWishlistItems,
    fetchWishlist,
    isInWishlist,
    isLoading,
    migrateLocalStorageWishlist,
    wishlistItems,
  };
};

export { useWishlist };
