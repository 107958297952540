export const useDialog = <T extends string | undefined = undefined>(
  name: string,
  defaultView?: T,
) => {
  const isDialogOpen = useState(`${name}Open`, () => false);

  const activeView = defaultView !== undefined
    ? useState<T>(`${name}View`, () => defaultView as T)
    : ref<T | null>(null);

  const afterCloseCallback = ref<(() => void) | null>(null);

  const openDialog = (
    view?: T,
    options: { afterClose?: () => void } = {},
  ) => {
    if (defaultView !== undefined && view !== undefined) {
      (activeView as Ref<T>).value = view;
    }

    afterCloseCallback.value = options.afterClose ?? null;
    isDialogOpen.value = true;
  };

  const closeDialog = () => {
    isDialogOpen.value = false;
    afterCloseCallback.value?.();
    afterCloseCallback.value = null;
  };

  const setView: (view: T) => void = defaultView !== undefined
    ? (newView: T) => {
      (activeView as Ref<T>).value = newView;
    }
    : (_: T) => { /* No-op: `setView` is not used, in case `defaultView` was not provided */ };

  return {
    activeView: activeView as Ref<T>,
    closeDialog,
    isDialogOpen,
    openDialog,
    setView,
  };
};
