import { getLocaleByCode } from 'localization';
import { State } from '@/config/state';

type CustomerPayload = {
  cartArticlesAmount: number | null;
  firstname: string | null;
  id: string | null;
  isLoggedIn: boolean;
  lastname: string | null;
};

const useCustomer = () => {
  const { BASE_URL } = useBaseUrl();

  const { locale } = useI18n();

  const customer = useState<CustomerPayload | null>(State.CustomerPayload);

  const customerApiUrl = computed(() => `${BASE_URL}/web-api`);

  const localeHeader = computed(() => ({ 'X-Locale': getLocaleByCode(locale.value)?.iso ?? 'en-GB' }));

  const fetchCustomer = async () => {
    const response = await fetch(`${customerApiUrl.value}/customer-data`, {
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...localeHeader.value,
      },
    });

    const data = await response.json();

    if (response.ok) {
      customer.value = {
        cartArticlesAmount: data.payload.cartArticlesAmount ?? null,
        firstname: data.payload.firstName ?? null,
        id: data.payload.number ?? null,
        isLoggedIn: data?.payload?.isLoggedIn ?? false,
        lastname: data.payload.lastName ?? null,
      };
    }
  };

  const signup = async (payload: {
    email: string;
    firstname: string;
    lastname: string;
    // newsletter: boolean;
    password: string;
  }) => {
    const response = await fetch(`${customerApiUrl.value}/customer-signup`, {
      body: JSON.stringify(payload),
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...localeHeader.value,
      },
      method: 'POST',
    });

    const data = await response.json();

    if (!response.ok) {
      throw new Error(data?.message?.text ?? 'Signup failed');
    }
  };

  const login = async (payload: {
    email: string;
    password: string;
  }) => {
    const response = await fetch(`${customerApiUrl.value}/customer-login`, {
      body: JSON.stringify(payload),
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...localeHeader.value,
      },
      method: 'POST',
    });

    const data = await response.json();

    if (!response.ok) {
      throw new Error(data?.message?.text ?? 'Login failed');
    }

    if (data?.payload) {
      await fetchCustomer();
    }
  };

  const logout = async () => {
    const response = await fetch(`${customerApiUrl.value}/customer-logout`, {
      credentials: 'include',
      method: 'POST',
    });

    customer.value = null;

    if (!response.ok) {
      throw new Error('Logout failed');
    }
  };

  const forgotPassword = async (email: string) => {
    const response = await fetch(`${customerApiUrl.value}/customer-password-reset`, {
      body: JSON.stringify({ email }),
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...localeHeader.value,
      },
      method: 'POST',
    });

    const data = await response.json();

    if (!response.ok) {
      throw new Error(data?.message?.text ?? 'Forgot password failed');
    }
  };

  return {
    customer,
    fetchCustomer,
    forgotPassword,
    login,
    logout,
    signup,
  };
};

export { useCustomer };
